import React from "react"
import { ThemeProvider } from "@material-ui/styles"
import { IntlProvider, addLocaleData } from "react-intl"
import { CssBaseline, NoSsr } from "@material-ui/core"
import { graphql } from "gatsby"
import CtaParser from "../../../utilities/marketingCtaParser"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import {
  Header,
  HeroBannerHalfSize,
  Footer,
  CartProvider,
  ProductLine,
  SEO,
  MobileOrientation,
} from "../../../components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Theme from "../theme"

import LeafLeft from "../images/leaf left.png"
import SeedImage from "../images/seed.png"
import LeafRight from "../images/leaf right.png"

import locale_es from "react-intl/locale-data/es"
import locale_en from "react-intl/locale-data/en"

import "../global_styles/global.css"

addLocaleData([...locale_en, ...locale_es])

const IndexPage = ({ data, pageContext, location }) => {
  const langKey = pageContext.locale
  const i18nmessages = require(`../messages/index/${langKey}`)
  const queryString = require("query-string")
  const parsedQuery = queryString.parse(location.search)
  const slides = data.content.edges[0].node.sierraNevadaHeroBanner.slides
  const heroBannerMediaPosition =
    data.content.edges[0].node.sierraNevadaHeroBanner.mediaPosition
  const heroBannerColor =
    data.content.edges[0].node.sierraNevadaHeroBanner.heroBannerColor
  const slideData = []

  for (let slide of slides) {
    let slideObject = {
      titleText: slide.richTitle,
      copyText: slide.richDescription,
      mediaPosition: heroBannerMediaPosition,
      color: heroBannerColor,
    }
    slideData.push(slideObject)
  }

  const slideMedia = {
    videoSrc: data.content.edges[0].node.sierraNevadaHeroBanner.media.file.url,
    imageSrc:
      data.content.edges[0].node.sierraNevadaHeroBanner.media.file.fluid,
    mediaType: data.content.edges[0].node.sierraNevadaHeroBanner.mediaType,
  }

  const productLineData = {
    titleText: data.content.edges[0].node.productLine.titleText,
    items: [
      {
        id: data.content.edges[0].node.productLine.productOne.id,
        title:
          data.content.edges[0].node.productLine.productOne.productLineTitle,
        imageSrc: data.content.edges[0].node.productLine.productOne.image.fixed,
        buttonText:
          data.content.edges[0].node.productLine.productOne.cardButtonText,
        color: data.content.edges[0].node.productLine.productOne.color,
        slug: data.content.edges[0].node.productLine.productOne.slug,
        locale: data.content.edges[0].node.productLine.node_locale,
        isProductLine: true,
        langKey: langKey,
      },
      {
        id: data.content.edges[0].node.productLine.productTwo.id,
        title:
          data.content.edges[0].node.productLine.productTwo.productLineTitle,
        imageSrc: data.content.edges[0].node.productLine.productTwo.image.fixed,
        buttonText:
          data.content.edges[0].node.productLine.productTwo.cardButtonText,
        color: data.content.edges[0].node.productLine.productTwo.color,
        slug: data.content.edges[0].node.productLine.productTwo.slug,
        locale: data.content.edges[0].node.productLine.node_locale,
        isProductLine: true,
        langKey: langKey,
      },
      {
        id: data.content.edges[0].node.productLine.productThree.id,
        title:
          data.content.edges[0].node.productLine.productThree.productLineTitle,
        imageSrc:
          data.content.edges[0].node.productLine.productThree.image.fixed,
        buttonText:
          data.content.edges[0].node.productLine.productThree.cardButtonText,
        color: data.content.edges[0].node.productLine.productThree.color,
        slug: data.content.edges[0].node.productLine.productThree.slug,
        locale: data.content.edges[0].node.productLine.node_locale,
        isProductLine: true,
        langKey: langKey,
      },
    ],
  }

  const pageMetadata = {
    title: data.content.edges[0].node.metadataTitle,
    description:
      data.content.edges[0].node.metadataDescription.internal.content,
    url: location.href,
  }

  const {
    transitionTime,
    defaultTransitionTime,
  } = require("../../../constants/randomConsts")

  return (
    <PageTransition
      transitionTime={
        parsedQuery.transition ? transitionTime : defaultTransitionTime
      }
    >
      <IntlProvider locale={langKey} messages={i18nmessages}>
        <React.Fragment>
          <CssBaseline />
          <ThemeProvider theme={Theme}>
            <MobileOrientation />
            <SEO siteMetadata={pageMetadata} />
            <CartProvider cartId={undefined}>
              <Header
                data={data.header.edges[0].node}
                slug={pageContext.slug}
              />
              <HeroBannerHalfSize
                isProductLine={false}
                static={true}
                slideData={slideData}
                slideMedia={slideMedia}
              />
              <ProductLine
                productLineData={productLineData}
                langKey={langKey}
              />
              <Footer
                data={data.footer.edges[0].node}
                langKey={langKey}
                bgImgLeft={LeafLeft}
                bgImgRight={LeafRight}
              />
            </CartProvider>
          </ThemeProvider>
        </React.Fragment>
      </IntlProvider>
    </PageTransition>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query($locale: String!) {
    content: allContentfulSierraNevadaPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          sierraNevadaHeroBanner {
            mediaPosition
            heroBannerColor
            media {
              file {
                url
              }
            }
            mediaType
            slides {
              richDescription {
                content {
                  json
                }
                fontColor
                fontFamily
                fontSize
                fontWeight
                letterSpacing
                lineHeight
                textTransform
              }
            }
            slides {
              richTitle {
                content {
                  json
                }
                fontColor
                fontFamily
                fontSize
                fontWeight
                letterSpacing
                lineHeight
                textTransform
              }
            }
          }
          productLine {
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            node_locale
            productOne {
              productLineTitle {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              image {
                fixed(width: 320) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              cardButtonText {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              color
              slug
            }
            productThree {
              productLineTitle {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              image {
                fixed(width: 320) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              cardButtonText {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              color
              slug
            }
            productTwo {
              productLineTitle {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              image {
                fixed(width: 320) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              cardButtonText {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              color
              slug
            }
          }
          metadataTitle
          metadataDescription {
            internal {
              content
            }
          }
        }
      }
    }
    header: allContentfulHeader {
      edges {
        node {
          shoppingCartLogo {
            file {
              url
            }
          }
          mainLogo {
            file {
              url
            }
          }
        }
      }
    }
    footer: allContentfulFooter(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          copyright {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          footerMenuItems {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          newsletterEmail {
            buttonText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            emailLable {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            buttonColor
            emailPlaceholder
          }
          socialMediaTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          discoverLogo {
            file {
              url
            }
          }
          amexLogo {
            file {
              url
            }
          }
          facebookLogo {
            file {
              url
            }
          }
          instagramLogo {
            file {
              url
            }
          }
          masterCardLogo {
            file {
              url
            }
          }
          payULogo {
            file {
              url
            }
          }
          visaLogo {
            file {
              url
            }
          }
          youTubeLogo {
            file {
              url
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`
